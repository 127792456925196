'use client';

import { createContext, useContext } from 'react';

import { SigninFlowConfig } from '@/shared/types';

export type SigninFormConfigContextValue = {
  config: SigninFlowConfig;
  setConfig: (config: SigninFlowConfig) => void;
};

export const SigninFormConfigContext = createContext(
  {} as SigninFormConfigContextValue,
);

export const useSigninFormConfigContext = () =>
  useContext(SigninFormConfigContext);

export const SigninFormConfigContextProvider = ({
  value,
  children,
}: {
  children: React.ReactNode;
  value: SigninFormConfigContextValue;
}) => (
  <SigninFormConfigContext.Provider value={value}>
    {children}
  </SigninFormConfigContext.Provider>
);
